<template>
    <div>
        <v-dialog v-model="visible" persistent max-width="650" class="dialog">
            <div class="card-body dialog">
                <v-row style="justify-content: space-between; width: 100%;">
                    <v-col lg="8">
                        <h3 class="general-text-title model-title">FAQ</h3>
                    </v-col>
                    <v-col lg="1">
                        <v-icon color="white" size="25" class="mt-4 ml-10" @click="close()">mdi-close</v-icon>
                    </v-col>
                </v-row>
                <div class="card-body mt-5" v-if="isEdit">
                    <div class="form-group mt-5">
                        <p>Title</p>
                        <input type="text" v-model="faq.question" />
                    </div>
                    <div class="form-group mt-5">
                        <p>Description</p>
                        <textarea type="text" v-model="faq.answer" rows="4"></textarea>
                    </div>
                    <div class="form-group mt-5">
                        <p>Applicable For?</p>
                        <select v-model="faq.for" class="mySelect">
                            <option value="3d-creators" style="color: black;">3D Creators</option>
                            <option value="metaverse-project-owners" style="color: black;">Metaverse Project Owners
                            </option>
                            <option value="general" style="color: black;">General</option>
                        </select>
                    </div>
                </div>
                <div class="card-body mt-5" v-else>
                    <div class="form-group mt-5">
                        <p>Title</p>
                        <input type="text" v-model="faqForm.question" />
                    </div>
                    <div class="form-group mt-5">
                        <p>Description</p>
                        <textarea type="text" v-model="faqForm.answer" rows="4"></textarea>
                    </div>
                    <div class="form-group mt-5">
                        <p>Applicable For?</p>
                        <select v-model="faqForm.for" class="mySelect">
                            <option value="3d-creators" style="color: black;">3D Creators</option>
                            <option value="metaverse-project-owners" style="color: black;">Metaverse Project Owners
                            </option>
                            <option value="general" style="color: black;">General</option>
                        </select>
                    </div>
                </div>
                <v-btn class="button btn mt-5" @click="onSubmitClick()">Submit</v-btn>
            </div>
        </v-dialog>
    </div>
</template>
<script>
import { faqObj } from '../../services/faq-services';

export default {
    name: 'FAQEditable',
    props: ['visible', 'faq', 'isEdit'],
    data() {
        return {
            faqForm: {
                question: "",
                answer: "",
                for: "",
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async onSubmitClick() {
            if (!this.isEdit) {
                await faqObj.createFAQ(this.faqForm);
            } else {
                await faqObj.update(this.faq, this.faq.id);
            }
            this.$emit('close');
        }
    },
}
</script>
<style scoped>
.dialog {
    background: var(--empty-color);
    padding: 20px;
    border-radius: 10px;
    background-image: url("../../assets/popup.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.span {
    width: 20px;
    height: 20px;
    font-size: 15px;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.model-title {
    font-size: 40px !important;
    text-align: left;
}

.form-group>p {
    margin-bottom: 0px;
    margin-left: 0px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-weight: 400;
}

.form-group>input {
    width: 100%;
    height: 50px;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid #ff7f3f;
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
}

.form-group>textarea {
    width: 100%;
    background: rgba(196, 196, 196, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid #ff7f3f;
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
}

.mySelect {
    width: 100%;
    height: 50px;
    background: rgba(196, 196, 196, 0.1) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid var(--border-color) !important;
    outline: none;
    color: #fff;
    font-family: "Archivo", sans-serif;
    padding: 5px;
    -webkit-appearance: menulist
}

.btn {
    border-radius: 10px;
    font-size: 18px;
    color: white;
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 56.2%,
            #ff3f7f 98.17%);
    font-weight: 600 !important;
    font-family: "Bebas Neue", cursive !important;
}
</style>