<template>
    <div class="faq-container">
        <h3>Things you can do:</h3>
        <p class="sub-title">Congratulations and welcome to Signa X, through this platform, you can:</p>
        <div style="
        width: 75%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        " @click="showFAQ(-1)" v-if="user.role === 'team'">
            <v-icon color="#ff7f3f" size="40">mdi-plus-circle</v-icon>
        </div>
        <div class="content d-flex mt-10">
            <v-expansion-panels dark v-model="selectedQuestion" style="width: 75%">
                <v-expansion-panel v-for="(content, index) in questions" :key="index" style="margin-bottom: 20px;"
                    class="expansion">
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col lg="10">
                                <div class="title-header">
                                    <img :src="`${content['selected']}`" width="20px" style="margin-right: 10px;"
                                        v-if="selectedQuestion === index" />
                                    <img :src="`${content['not-selected']}`" width="20px" style="margin-right: 10px;"
                                        v-else />
                                    <p
                                        :class="selectedQuestion === index ? 'content-header-selected' : 'content-header'">
                                        {{ content.question }}</p>
                                </div>
                            </v-col>
                            <v-col lg="2" v-if="user.role === 'team'">
                                <v-btn icon @click="showFAQ(index)">
                                    <v-icon size="20">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon @click="showDelete(content)">
                                    <v-icon size="20" color="red">mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <p class="content-response">{{ content.answer }}</p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
        <FAQEditable :visible="showEdit" :faq="selectedFAQ" :isEdit="selectedFAQ === null ? false : true"
            @close="close"></FAQEditable>
        <v-dialog v-model="showWarning" persistent max-width="500" transition="dialog-bottom-transition" class="dialog">
            <v-card :color="'#15181E'" class="model-card text-center dialog">
                <v-row justify="space-between" style="width: 100%">
                    <v-col lg="12">
                        <div :class="'general-text-title model-title'">
                            Are you sure you want to delete?
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col lg="4">
                        <v-btn class="mt-10 button button-data" @click="deleteOperation()">Yes</v-btn>
                    </v-col>
                    <v-col lg="4">
                        <v-btn class="mt-10 button button-data-secondary" @click="close()">No</v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import FAQEditable from '../components/Modals/FAQEditable.vue';
import { mapState } from 'vuex';
import { faqObj } from '../services/faq-services';

export default {
    name: "FAQPage",
    data() {
        return {
            questions: [
            ],
            selectedQuestion: -1,
            showEdit: false,
            selectedFAQ: null,
            showWarning: false,
        };
    },
    methods: {
        showFAQ(index) {
            if (index !== -1) {
                this.selectedFAQ = this.questions[index];
            }
            this.showEdit = true;
        },
        close() {
            this.showEdit = false;
            this.showWarning = false;
        },
        async getAllFAQ() {
            try {
                if (this.user.role === 'team') {
                    const data = await faqObj.getAllFAQ();
                    this.questions = data;

                } else if (this.user.role === 'designer') {
                    const data = await faqObj.getAllFAQDesigner('3d-creators');
                    this.questions = data;

                } else {
                    const data = await faqObj.getAllFAQMeta('metaverse-project-owners');
                    this.questions = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        showDelete(content) {
            this.selectedFAQ = content;
            this.showWarning = true;
        },
        async deleteOperation() {
            try {
                await faqObj.delete(this.selectedFAQ.id);
                this.close();
                this.questions.splice(this.selectedQuestion, 1);
                this.selectedQuestion = -1;
            } catch (error) {

            }
        }
    },
    components: { FAQEditable },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
    },
    created() {
        this.getAllFAQ();
    },
}
</script>
<style scoped>
.faq-container {
    width: 100%;
    min-height: 100vh;
    /* background-image: url("../assets/market/market_bg.svg"); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../assets/hue.svg");
    padding: 20px;
    padding-top: 20px !important;
}

h3 {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 56.2%,
            #ff3f7f 98.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "Orbitron", sans-serif;
    font-size: 30px;
    font-weight: 800;
    margin: 00px 0px 0px 0px;
    width: 30%;
}

.sub-title {
    color: rgba(196, 196, 196, 0.5);
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
}

.expansion {
    background: rgba(196, 196, 196, 0.1) !important;
    box-shadow: none !important;
}

.content {
    font-family: 'DM Sans', sans-serif !important;
}

.content-header {
    margin: 0px 0px 0px 20px;
    color: rgba(255, 255, 255, 0.8);
    font-family: 'DM Sans', sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

.content-header-selected {
    margin: 0px 0px 0px 20px;
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'DM Sans', sans-serif !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
}

.content-response {
    margin: 0px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.7);
}

.title-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.model-card {
    padding: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-data {
    background: linear-gradient(97.3deg,
            #ffbf3f -2.55%,
            #ff7f3f 26.2%,
            #ff3f7f 98.17%);
    font-family: "Bebas Neue", cursive;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    letter-spacing: 0.8px !important;
    color: white;
    font-size: 20px;
}

.model-title {
    font-size: 20px !important;
    text-align: center;
}

.button-data-secondary {
    font-family: "Bebas Neue", cursive;
    text-transform: capitalize !important;
    font-weight: 400 !important;
    letter-spacing: 0.8px !important;
    color: white;
    font-size: 20px;
    border: 1px solid #ff7f3f;
    background: transparent !important;
}
</style>
